import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

export const Completed = () => {
  const { width, height } = useWindowSize();
  const [isConfettiActive, setIsConfettiActive] = useState(true);

  useEffect(() => {
    const prevOverflow = document.body.style.overflowX;
    document.body.style.overflowX = 'hidden';
    setTimeout(() => {
      document.body.style.overflowX = prevOverflow;
      setIsConfettiActive(false);
    }, 3500);
  }, []);

  return (
    <>
      <div className='max-w-[500px] bg-white mx-auto px-6 pt-6 flex flex-col items-center rounded space-y-6 flex-grow pb-14'>
        <img src='/images/complete.png' alt='Complete' className='w-40 h-40' />
        <h1 className='text-2xl lg:text-3xl font-semibold text-secondary text-center'>
          You have successfully completed the process!
        </h1>
        <div className='text-center lg:text-2xl text-xl whitespace-pre-line space-y-2'>
          <div>All steps completed - that's a wrap!</div>
          <div>
            Our Customer Success Team is now prepping to provide you with the
            next steps. You'll hear from us very soon, so keep an eye on your
            inbox!
          </div>
        </div>
      </div>
      {isConfettiActive && (
        <Confetti width={width} height={height} run={isConfettiActive} />
      )}
    </>
  );
};
