import { useEffect, useState } from 'react';
import { postInvite } from '../../core/api';
import { useToast } from '../../contexts/useToast';
import { Spinner } from '../Spinner';
import { useSteps } from '../../contexts/useSteps';

const FileInvite = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState('');
  const { toast } = useToast();
  const { fileInviteStatus } = useSteps();

  useEffect(() => {
    setIsLoading(true);
    postInvite()
      .then((res) => {
        setLink(res.url);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast('Something went wrong!', 'error');
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && (
        <Spinner text='Please wait while your link is being generated' />
      )}
      {link && (
        <div className='space-y-4 text-center'>
          {fileInviteStatus && (
            <div className='text-xl'>Fileinvite status: {fileInviteStatus}</div>
          )}
          <a
            className='text-3xl text-secondary semibold ubderline hover:underline cursor-pointer inline-block'
            href={link}
            target='_blank'
            rel='noopener noreferrer'
          >
            Open file invite link in new tab
          </a>
        </div>
      )}
    </>
  );
};

export default FileInvite;
