import axios from 'axios';
import {
  Contact,
  PostInviteResponse,
  PostSurveyInput,
  PredictionsResponse,
} from '../../types';
import { IdToken, LogoutOptions } from '@auth0/auth0-react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const client = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const initializeInterceptors = (
  getToken: () => Promise<string>,
  logout: (options?: LogoutOptions | undefined) => Promise<void>,
  getIdTokenClaims: () => Promise<IdToken | undefined>
) => {
  client.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      const claims = await getIdTokenClaims();

      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['IdToken'] = claims?.__raw;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  client.interceptors.response.use(
    async (config) => {
      return config;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );
};

export const getContact = async () => {
  const res = await client.get<Contact>(`/api/contact`);
  return res.data;
};

export const postSurvey = async (data: PostSurveyInput) => {
  const res = await client.post<PostSurveyInput>(`/api/survey`, data);
  return res.data;
};

export const updateContact = async (data: Partial<PostSurveyInput>) => {
  const res = await client.post<PostSurveyInput>(`/api/contact`, data);
  return res.data;
};

export const postTaxPayers = async () => {
  const res = await client.post<{
    url: string;
  }>(`/api/taxstatus/taxpayers`);
  return res.data;
};

export const getStep = async () => {
  const res = await client.get<number>(`/api/steps`);
  return res.data;
};

export const patchStep = async (step: number) => {
  const res = await client.patch(`/api/steps`, {
    dashboard_current_step: step,
  });
  return res.data;
};

export const postEnvelope = async () => {
  const res = await client.post<{
    url: string;
  }>(`/api/docusign/envelopes`);
  return res.data;
};

export const postInvite = async () => {
  const res = await client.post<PostInviteResponse>(`/api/fileinvite/invites`);
  return res.data;
};

export const getAdressPrediction = async (input: string) => {
  const res = await client.get<PredictionsResponse>(`/api/places`, {
    params: {
      input,
    },
  });
  return res.data;
};
