import { postEnvelope } from '../../core/api';
import { useEffect, useState } from 'react';
import { Spinner } from '../Spinner';

const DocuSign = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState('');

  useEffect(() => {
    postEnvelope()
      .then((res) => {
        setLink(res.url);
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {isLoading && (
        <Spinner text='Please wait while your link is being generated' />
      )}
      {/* <div className='my-4 text-center'>
        <a
          href={`https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature%20extende&client_id=${process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY}&redirect_uri=${process.env.REACT_APP_API_URL}/api/docusign/callback`}
          className='text-3xl underline hover:no-underline text-primary'
        >
          Give a Consent
        </a>
      </div> */}
      {link && (
        <iframe
          className='border-none rounded-md w-full min-h-screen'
          title='DocuSign'
          src={link || 'https://www.docusign.com'}
          onLoad={() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 3000);
          }}
        />
      )}
    </div>
  );
};

export default DocuSign;
