import { useState } from 'react';
import { Spinner } from '../Spinner';

const FinchPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className='max-w-[500px] mx-auto'>
      {isLoading && (
        <Spinner text='Please wait while your link is being generated' />
      )}
      <iframe
        className='border-none rounded-md w-[500px] min-h-[700px]'
        title='FinchPage'
        src='https://connect.smarterc.com/'
        onLoad={() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }}
      ></iframe>
    </div>
  );
};

export default FinchPage;
