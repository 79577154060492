import { ChangeEvent, ChangeEventHandler, useMemo, useState } from 'react';
import {
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  Box,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

import InputMask from 'react-input-mask';
import './Survey.css';
import LoadingButton from '@mui/lab/LoadingButton';
import { postSurvey } from '../../core/api';
import { useAuth0 } from '@auth0/auth0-react';
import { FormikErrors, useFormik } from 'formik';
import { PostSurveyInput, SurveyFormValues } from '../../types';
import CheckIcon from '@mui/icons-material/Check';
import { useSteps } from '../../contexts/useSteps';
import { useToast } from '../../contexts/useToast';
import { AddressInput } from '../AddressInput';
import { InputWithAutocomplete } from '../InputWithAutocomplete';
import {
  getMonth,
  isEmail,
  isFEINValid,
  legalTitleOptions,
  STATES_OPTIONS,
  taxFormOptions,
  months,
  isPhoneValid,
} from './Survey.helpers';

const normalizeSurveyFormData = (
  formValues: SurveyFormValues,
  userEmail: string,
  contactPhone: string
): PostSurveyInput => {
  return {
    ...formValues,
    docusign__signer__first_name: formValues.firstname_dsh,
    docusign__signer__last_name: formValues.lastname_dsh,
    docusign__signer__full_name: `${formValues.firstname_dsh} ${formValues.lastname_dsh}`,
    docusign__signer__phone_number: formValues.company_phone_dsh,
    docusign__signer__title: formValues.title_dsh,
    docusign_ein_tin: formValues.fein_dsh,
    docusign_legal_company_name: formValues.legal_company_name_dsh,
    docusign_street_address: formValues.streetaddress_dsh,
    docusign_street_address2: formValues.unitnumber_dsh,
    docusign_city: formValues.city_dsh,
    docusign_state_region__select_: formValues.state_dsh,
    docusign_postal_code: formValues.zip_dsh,
    docusign__signer__email: formValues.emailforlegalnotices_dsh,
    docusign_point_of_contact_for_legal_notices: formValues.pointofcontact_dsh,
    docusign_email_for_poc_for_legal_notices:
      formValues.emailforlegalnotices_dsh,
    taxstatus_individual_firstname: formValues.firstname_dsh,
    taxstatus_individual_lastname: formValues.lastname_dsh,
    taxstatus_individual_email: userEmail,
    taxstatus_individual_mobile: contactPhone,
    taxstatus_business_fein: formValues.fein_dsh,
    taxstatus_business_phone: contactPhone,
    taxstatus_business_businessname: formValues.legal_company_name_dsh,
    taxstatus_business_title: formValues.legaltitle_dsh,
    taxstatus_business_fiscal: String(getMonth(formValues.fiscalyearend_dsh)),
    taxstatus_business_street:
      formValues.businessaddress_dsh + ' ' + formValues.business_unitnumber_dsh,
    taxstatus_business_city: formValues.business_city_dsh,
    taxstatus_business_state: formValues.business_state_dsh,
    taxstatus_business_zip: formValues.business_zip_dsh,
    dashboard_current_step: '1',
  };
};

const Survey = () => {
  const { setCurrentStep } = useSteps();
  const { user } = useAuth0();
  const [isBusinessAddressSame, setIsBusinessAddressSame] = useState(false);
  const { toast } = useToast();

  const years = useMemo(
    () =>
      Array.from({ length: new Date().getFullYear() - 1950 + 1 }, (_, index) =>
        String(1950 + index)
      ).reverse(),
    []
  );

  const formik = useFormik<SurveyFormValues>({
    enableReinitialize: true,
    initialValues: {
      firstname_dsh: '',
      lastname_dsh: '',
      company_phone_dsh: '',
      title_dsh: '',
      legaltitle_dsh: '',
      legal_company_name_dsh: '',
      fein_dsh: '',
      // mailingaddress_dsh: '',
      pointofcontact_dsh: '',
      streetaddress_dsh: '',
      unitnumber_dsh: '',
      city_dsh: '',
      state_dsh: '',
      zip_dsh: '',
      business_unitnumber_dsh: '',
      businessaddress_dsh: '',
      business_city_dsh: '',
      business_state_dsh: '',
      business_zip_dsh: '',
      emailforlegalnotices_dsh: '',
      isowner_dsh: '',
      filedbefore_dsh: '',
      filed2020q2_dsh: 'No',
      filed2020q3_dsh: 'No',
      filed2020q4_dsh: 'No',
      filed2021q1_dsh: 'No',
      filed2021q2_dsh: 'No',
      filed2021q3_dsh: 'No',
      filed2021q4_dsh: 'No',
      numemployees2019_dsh: '',
      numemployees2020_dsh: '',
      numemployees2021_dsh: '',
      taxform_dsh: '',
      fiscalyearend_dsh: '',
      firstyeartaxreturn_dsh: '',
    },
    validate: (values) => {
      const errors: FormikErrors<SurveyFormValues> = {};

      if (!values.firstname_dsh) {
        errors.firstname_dsh = 'First name of contract signatory is required';
      }
      if (!values.lastname_dsh) {
        errors.lastname_dsh = 'Last name of contract signatory is required';
      }
      if (!values.company_phone_dsh) {
        errors.company_phone_dsh = 'Required';
      }
      if (!isPhoneValid(values.company_phone_dsh)) {
        errors.company_phone_dsh = 'Phone is invalid';
      }
      if (!values.title_dsh) {
        errors.title_dsh = 'Position/Title of contract signatory is required';
      }
      if (!values.legaltitle_dsh) {
        errors.legaltitle_dsh = 'Legal title of contract signatory is required';
      }
      if (!values.legal_company_name_dsh) {
        errors.legal_company_name_dsh = 'Required';
      }
      if (!values.fein_dsh) {
        errors.fein_dsh =
          'Federal Employer Identification Number (FEIN) is required';
      }
      // if (!values.mailingaddress_dsh) {
      //   errors.mailingaddress_dsh =
      //     'Mailing address for legal Nntice is required';
      // }
      if (!values.pointofcontact_dsh) {
        errors.pointofcontact_dsh =
          'Point of contact for cegal cotices is required';
      }
      if (!values.streetaddress_dsh) {
        errors.streetaddress_dsh =
          'Street address for (legal) notices is required';
      }
      if (!values.unitnumber_dsh) {
        errors.unitnumber_dsh = 'Unit number for notices address is required';
      }
      if (!values.city_dsh) {
        errors.city_dsh = 'Required';
      }
      if (!values.state_dsh) {
        errors.state_dsh = 'Required';
      }
      if (!values.zip_dsh) {
        errors.zip_dsh = 'Required';
      }
      if (!values.businessaddress_dsh) {
        errors.businessaddress_dsh = 'Required';
      }
      if (!values.business_unitnumber_dsh) {
        errors.business_unitnumber_dsh = 'Required';
      }
      if (!values.business_city_dsh) {
        errors.business_city_dsh = 'Required';
      }
      if (!values.business_state_dsh) {
        errors.business_state_dsh = 'Required';
      }
      if (!values.business_zip_dsh) {
        errors.business_zip_dsh = 'Required';
      }
      if (!values.emailforlegalnotices_dsh) {
        errors.emailforlegalnotices_dsh = 'Email for legal notices is required';
      }
      if (!values.isowner_dsh) {
        errors.isowner_dsh = 'Required';
      }
      if (!values.filedbefore_dsh) {
        errors.filedbefore_dsh = 'Required';
      }
      if (!values.filed2020q2_dsh) {
        errors.filed2020q2_dsh = 'Required';
      }
      if (!values.filed2020q3_dsh) {
        errors.filed2020q3_dsh = 'Required';
      }
      if (!values.filed2020q4_dsh) {
        errors.filed2020q4_dsh = 'Required';
      }
      if (!values.filed2021q1_dsh) {
        errors.filed2021q1_dsh = 'Required';
      }
      if (!values.filed2021q2_dsh) {
        errors.filed2021q2_dsh = 'Required';
      }
      if (!values.filed2021q3_dsh) {
        errors.filed2021q3_dsh = 'Required';
      }
      if (!values.filed2021q4_dsh) {
        errors.filed2021q4_dsh = 'Required';
      }
      if (!values.numemployees2019_dsh) {
        errors.numemployees2019_dsh =
          'Please type the number of employees you had in 2019?';
      }
      if (!values.numemployees2020_dsh) {
        errors.numemployees2020_dsh =
          'Please type the number of employees you had in 2020?';
      }
      if (!values.numemployees2021_dsh) {
        errors.numemployees2021_dsh =
          'Please type the number of employees you had in 2021?';
      }
      if (!values.taxform_dsh) {
        errors.taxform_dsh = 'Please select which tax form do you file?';
      }
      if (!values.fiscalyearend_dsh) {
        errors.fiscalyearend_dsh =
          'Please select what month is your fiscal year end?';
      }
      if (!values.firstyeartaxreturn_dsh) {
        errors.firstyeartaxreturn_dsh =
          'Please what is the first year in which you filed a tax return?';
      }

      const curYear = new Date().getFullYear();
      if (
        values.firstyeartaxreturn_dsh &&
        (+values.firstyeartaxreturn_dsh < 1950 ||
          +values.firstyeartaxreturn_dsh > curYear)
      ) {
        errors.firstyeartaxreturn_dsh = `Year should be between 1950 and ${curYear}`;
      }

      if (
        values.emailforlegalnotices_dsh &&
        !isEmail(values.emailforlegalnotices_dsh)
      ) {
        errors.emailforlegalnotices_dsh = 'Invalid email address';
      }

      if (values.fein_dsh && !isFEINValid(values.fein_dsh)) {
        errors.fein_dsh = 'Invalid FEIN';
      }

      return errors;
    },
    onSubmit: (values) => {
      if (!user) return;

      postSurvey(
        normalizeSurveyFormData(
          values,
          user?.email!,
          values.company_phone_dsh.replace(/-/g, '')
        )
      )
        .then(() => {
          setCurrentStep((prev) => prev + 1);
          toast('Form submitted successfully!', 'success');
        })
        .catch((err) => {
          console.error(err);
          toast('Something went wrong!', 'error');
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const onChangeNumberField = (value: string, name: string) => {
    if (isNaN(+value)) {
      return;
    }

    formik.setFieldTouched(name, true, true);
    formik.setFieldValue(name, value.split('.')[0], true);
  };

  const onChangeNumberInput: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    onChangeNumberField(e.target.value, e.target.name);
  };

  const onCheckboxChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, checked } = e.target;
    formik.setFieldValue(name, checked ? 'Yes' : 'No', true);
    formik.setFieldTouched(name, true, true);
  };

  const isChecked = (value: string) => {
    return value === 'Yes';
  };

  console.log(
    'formik.values.company_phone_dsh',
    formik.values.company_phone_dsh.replace(/-/g, '')
  );

  return (
    <div className='Survey bg-white rounded-md px-8 py-6'>
      <form
        className='initial-survey-form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className='personal-information'>
              <div>
                <h3>Personal Information</h3>
              </div>

              <div className='text-field'>
                <InputLabel htmlFor='firstname_dsh' className=''>
                  First Name of Contract Signatory
                </InputLabel>
                <TextField
                  name='firstname_dsh'
                  value={formik.values.firstname_dsh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormHelperText error>
                  {formik.errors.firstname_dsh && formik.touched.firstname_dsh
                    ? formik.errors.firstname_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='lastname_dsh'>
                  Last Name of Contract Signatory
                </InputLabel>
                <TextField
                  name='lastname_dsh'
                  value={formik.values.lastname_dsh}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormHelperText error>
                  {formik.errors.lastname_dsh && formik.touched.lastname_dsh
                    ? formik.errors.lastname_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='title_dsh'>
                  Position/Title of Contract Signatory
                </InputLabel>
                <TextField
                  name='title_dsh'
                  value={formik.values.title_dsh}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormHelperText error>
                  {formik.errors.title_dsh && formik.touched.title_dsh
                    ? formik.errors.title_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='legaltitle_dsh'>
                  Legal Title of Contract Signatory
                </InputLabel>
                <FormControl>
                  <Select
                    name='legaltitle_dsh'
                    value={formik.values.legaltitle_dsh}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label='Legal Title'
                  >
                    {legalTitleOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error className='!m-0'>
                    {formik.errors.legaltitle_dsh &&
                    formik.touched.legaltitle_dsh
                      ? formik.errors.legaltitle_dsh
                      : ''}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='legal_company_name_dsh'>
                  Legal Company Name
                </InputLabel>
                <TextField
                  name='legal_company_name_dsh'
                  value={formik.values.legal_company_name_dsh}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormHelperText error>
                  {formik.errors.legal_company_name_dsh &&
                  formik.touched.legal_company_name_dsh
                    ? formik.errors.legal_company_name_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='company_phone_dsh'>
                  Company Phone
                </InputLabel>
                <InputMask
                  name='company_phone_dsh'
                  value={formik.values.company_phone_dsh}
                  mask='999-999-9999'
                  maskChar={null}
                  maskPlaceholder=''
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {/* @ts-ignore */}
                  {(inputProps) => <TextField {...inputProps} />}
                </InputMask>
                <FormHelperText error>
                  {formik.errors.company_phone_dsh &&
                  formik.touched.company_phone_dsh
                    ? formik.errors.company_phone_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='fein_dsh'>
                  Federal Employer Identification Number (FEIN)
                </InputLabel>
                <InputMask
                  name='fein_dsh'
                  mask='99-9999999'
                  maskChar={null}
                  value={formik.values.fein_dsh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {/* @ts-ignore */}
                  {(inputProps) => <TextField {...inputProps} />}
                </InputMask>
                <FormHelperText error>
                  {formik.errors.fein_dsh && formik.touched.fein_dsh
                    ? formik.errors.fein_dsh
                    : ''}
                </FormHelperText>
              </div>

              <div className='text-field'>
                <AddressInput
                  label='Street address for legal notices'
                  name='streetaddress_dsh'
                  error={
                    formik.errors.streetaddress_dsh &&
                    formik.touched.streetaddress_dsh
                      ? formik.errors.streetaddress_dsh
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.streetaddress_dsh}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (isBusinessAddressSame) {
                      formik.setFieldValue(
                        'businessaddress_dsh',
                        e.target.value,
                        true
                      );
                      formik.setFieldTouched('businessaddress_dsh', true, true);
                    }
                  }}
                />
              </div>

              <div className='text-field'>
                <InputLabel htmlFor='unitnumber_dsh'>
                  Unit number for legal notices
                </InputLabel>
                <TextField
                  name='unitnumber_dsh'
                  value={formik.values.unitnumber_dsh}
                  onChange={
                    isBusinessAddressSame
                      ? (e) => {
                          formik.setFieldValue(
                            e.target.name,
                            e.target.value,
                            true
                          );
                          formik.setFieldTouched(e.target.name, true, true);
                          formik.setFieldValue(
                            'business_unitnumber_dsh',
                            e.target.value,
                            true
                          );
                          formik.setFieldTouched(
                            'business_unitnumber_dsh',
                            true,
                            true
                          );
                        }
                      : formik.handleChange
                  }
                  onBlur={formik.handleBlur}
                />
                <FormHelperText error>
                  {formik.errors.unitnumber_dsh && formik.touched.unitnumber_dsh
                    ? formik.errors.unitnumber_dsh
                    : ''}
                </FormHelperText>
              </div>

              <div className='text-field'>
                <AddressInput
                  label='City for legal notices'
                  name='city_dsh'
                  error={
                    formik.errors.city_dsh && formik.touched.city_dsh
                      ? formik.errors.city_dsh
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.city_dsh}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (isBusinessAddressSame) {
                      formik.setFieldValue(
                        'business_city_dsh',
                        e.target.value,
                        true
                      );
                      formik.setFieldTouched('business_city_dsh', true, true);
                    }
                  }}
                />
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='state_dsh'>
                  State for legal notices
                </InputLabel>
                <FormControl>
                  <Select
                    name='state_dsh'
                    value={formik.values.state_dsh}
                    onChange={
                      isBusinessAddressSame
                        ? (e) => {
                            formik.setFieldValue(
                              'business_state_dsh',
                              e.target.value,
                              true
                            );
                            formik.handleChange(e);
                          }
                        : formik.handleChange
                    }
                    onBlur={formik.handleBlur}
                    label='Tax Form'
                  >
                    {STATES_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}&nbsp;({option.value})
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error className='!m-0'>
                    {formik.errors.state_dsh && formik.touched.state_dsh
                      ? formik.errors.state_dsh
                      : ''}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='zip_dsh'>Zip for legal notices</InputLabel>
                <TextField
                  name='zip_dsh'
                  value={formik.values.zip_dsh}
                  onChange={
                    isBusinessAddressSame
                      ? (e: ChangeEvent<HTMLInputElement>) => {
                          let { value } = e.target;
                          value = value.slice(0, 5);
                          if (isNaN(+value)) {
                            return;
                          }

                          onChangeNumberField(value, e.target.name);
                          formik.setFieldValue('business_zip_dsh', value, true);
                          formik.setFieldTouched(
                            'business_zip_dsh',
                            true,
                            true
                          );
                        }
                      : (e) =>
                          onChangeNumberField(
                            e.target.value.slice(0, 5),
                            e.target.name
                          )
                  }
                  onBlur={formik.handleBlur}
                />
                <FormHelperText error>
                  {formik.errors.zip_dsh && formik.touched.zip_dsh
                    ? formik.errors.zip_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='pointofcontact_dsh'>
                  Point of Contact for Legal Notices
                </InputLabel>
                <TextField
                  name='pointofcontact_dsh'
                  value={formik.values.pointofcontact_dsh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormHelperText error>
                  {formik.errors.pointofcontact_dsh &&
                  formik.touched.pointofcontact_dsh
                    ? formik.errors.pointofcontact_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='emailforlegalnotices_dsh'>
                  Email for Legal Notices
                </InputLabel>
                <TextField
                  type='email'
                  name='emailforlegalnotices_dsh'
                  value={formik.values.emailforlegalnotices_dsh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormHelperText error>
                  {formik.errors.emailforlegalnotices_dsh &&
                  formik.touched.emailforlegalnotices_dsh
                    ? formik.errors.emailforlegalnotices_dsh
                    : ''}
                </FormHelperText>
              </div>
              <Box className='text-field' marginBottom={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isBusinessAddressSame}
                      onChange={(e) => {
                        if (e.target.checked) {
                          formik.setFieldValue(
                            'business_city_dsh',
                            formik.values.city_dsh,
                            true
                          );
                          formik.setFieldValue(
                            'business_state_dsh',
                            formik.values.state_dsh,
                            true
                          );
                          formik.setFieldValue(
                            'business_zip_dsh',
                            formik.values.zip_dsh,
                            true
                          );
                          formik.setFieldValue(
                            'business_unitnumber_dsh',
                            formik.values.unitnumber_dsh,
                            true
                          );
                          formik.setFieldValue(
                            'businessaddress_dsh',
                            formik.values.streetaddress_dsh,
                            true
                          );
                        }
                        setIsBusinessAddressSame(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography variant='body1' fontSize={12}>
                      Is the address you used on your last tax return the same
                      address listed above?
                    </Typography>
                  }
                />
              </Box>
              <div className='text-field'>
                <AddressInput
                  label='Street of business address was used on your last tax return'
                  name='businessaddress_dsh'
                  error={
                    formik.errors.businessaddress_dsh &&
                    formik.touched.businessaddress_dsh
                      ? formik.errors.businessaddress_dsh
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.businessaddress_dsh}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='business_unitnumber_dsh'>
                  Unit number of business address was used on your last tax
                  return
                </InputLabel>
                <TextField
                  name='business_unitnumber_dsh'
                  value={formik.values.business_unitnumber_dsh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isBusinessAddressSame}
                />
                <FormHelperText error>
                  {formik.errors.business_unitnumber_dsh &&
                  formik.touched.business_unitnumber_dsh
                    ? formik.errors.business_unitnumber_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <AddressInput
                  label='City of business address was used on your last tax return'
                  name='business_city_dsh'
                  error={
                    formik.errors.business_city_dsh &&
                    formik.touched.business_city_dsh
                      ? formik.errors.business_city_dsh
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.business_city_dsh}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='business_city_dsh'>
                  State of business address was used on your last tax return
                </InputLabel>
                <TextField
                  name='business_state_dsh'
                  value={formik.values.business_state_dsh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isBusinessAddressSame}
                />
                <FormHelperText error>
                  {formik.errors.business_state_dsh &&
                  formik.touched.business_state_dsh
                    ? formik.errors.business_state_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='business_city_dsh'>
                  Zip of business address was used on your last tax return
                </InputLabel>
                <TextField
                  name='business_zip_dsh'
                  value={formik.values.business_zip_dsh}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChangeNumberField(
                      e.target.value.slice(0, 5),
                      e.target.name
                    );
                  }}
                  onBlur={formik.handleBlur}
                  disabled={isBusinessAddressSame}
                />
                <FormHelperText error>
                  {formik.errors.business_zip_dsh &&
                  formik.touched.business_zip_dsh
                    ? formik.errors.business_zip_dsh
                    : ''}
                </FormHelperText>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className='erc-estimate'>
              <div>
                <h3>ERC Estimate</h3>
              </div>

              <FormControl component='fieldset'>
                <FormLabel component='legend'>
                  Are you the Owner or acting on behalf of the owner?
                </FormLabel>

                <RadioGroup
                  aria-label='Is Owner'
                  name='isowner_dsh'
                  value={formik.values.isowner_dsh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <div className='radio-btn-yn-container'>
                    <FormControlLabel
                      value='true'
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='false'
                      control={<Radio />}
                      label='No'
                    />
                  </div>
                </RadioGroup>
                {formik.touched.isowner_dsh && formik.errors.isowner_dsh && (
                  <FormHelperText error className='!m-0'>
                    Please select whether you are the owner or acting on behalf
                    of the owner.
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl component='fieldset'>
                <FormLabel component='legend'>
                  Have you previously filed for ERC?
                </FormLabel>

                <RadioGroup
                  aria-label='Filed Before ERC'
                  name='filedbefore_dsh'
                  value={formik.values.filedbefore_dsh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <div className='radio-btn-yn-container'>
                    <FormControlLabel
                      value='true'
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='false'
                      control={<Radio />}
                      label='No'
                    />
                  </div>
                </RadioGroup>
                {formik.touched.filedbefore_dsh &&
                  formik.errors.filedbefore_dsh && (
                    <FormHelperText error className='!m-0'>
                      Please select whether you previously filed for ERC.
                    </FormHelperText>
                  )}
              </FormControl>

              <FormControl component='fieldset' className='text-field'>
                <FormLabel component='legend'>
                  For which Quarters did you file ERC?
                </FormLabel>

                <div className='radio-button-field-container'>
                  <div className='field-container-year'>2020:</div>
                  <div className='radio-button-field'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked(formik.values.filed2020q2_dsh)}
                          onChange={onCheckboxChange}
                          onBlur={formik.handleBlur}
                          name='filed2020q2_dsh'
                        />
                      }
                      label='Q2'
                      labelPlacement='start'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked(formik.values.filed2020q3_dsh)}
                          onChange={onCheckboxChange}
                          onBlur={formik.handleBlur}
                          name='filed2020q3_dsh'
                        />
                      }
                      label='Q3'
                      labelPlacement='start'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked(formik.values.filed2020q4_dsh)}
                          onChange={onCheckboxChange}
                          onBlur={formik.handleBlur}
                          name='filed2020q4_dsh'
                        />
                      }
                      label='Q4'
                      labelPlacement='start'
                    />
                  </div>
                </div>

                <div className='radio-button-field-container'>
                  <div className='field-container-year'>2021:</div>
                  <div className='radio-button-field'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked(formik.values.filed2021q1_dsh)}
                          onChange={onCheckboxChange}
                          onBlur={formik.handleBlur}
                          name='filed2021q1_dsh'
                        />
                      }
                      label='Q1'
                      labelPlacement='start'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked(formik.values.filed2021q2_dsh)}
                          onChange={onCheckboxChange}
                          onBlur={formik.handleBlur}
                          name='filed2021q2_dsh'
                        />
                      }
                      label='Q2'
                      labelPlacement='start'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked(formik.values.filed2021q3_dsh)}
                          onChange={onCheckboxChange}
                          onBlur={formik.handleBlur}
                          name='filed2021q3_dsh'
                        />
                      }
                      label='Q3'
                      labelPlacement='start'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked(formik.values.filed2021q4_dsh)}
                          onChange={onCheckboxChange}
                          onBlur={formik.handleBlur}
                          name='filed2021q4_dsh'
                        />
                      }
                      label='Q4'
                      labelPlacement='start'
                    />
                  </div>
                </div>
              </FormControl>

              <div className='text-field-custom'>
                <InputLabel htmlFor='numemployees2019_dsh'>
                  How many employees did you have in 2019?
                </InputLabel>

                <input
                  className='no-outline'
                  name='numemployees2019_dsh'
                  value={formik.values.numemployees2019_dsh}
                  onChange={onChangeNumberInput}
                  onBlur={formik.handleBlur}
                />
                <FormHelperText error>
                  {formik.errors.numemployees2019_dsh &&
                  formik.touched.numemployees2019_dsh
                    ? formik.errors.numemployees2019_dsh
                    : ''}
                </FormHelperText>
              </div>

              <div className='text-field-custom'>
                <InputLabel htmlFor='numemployees2020_dsh'>
                  How many employees did you have in 2020?
                </InputLabel>

                <input
                  className='no-outline'
                  name='numemployees2020_dsh'
                  value={formik.values.numemployees2020_dsh}
                  onChange={onChangeNumberInput}
                  onBlur={formik.handleBlur}
                />
                <FormHelperText error>
                  {formik.errors.numemployees2020_dsh &&
                  formik.touched.numemployees2020_dsh
                    ? formik.errors.numemployees2020_dsh
                    : ''}
                </FormHelperText>
              </div>

              <div className='text-field-custom'>
                <InputLabel htmlFor='numemployees2021_dsh'>
                  How many employees did you have in 2021?
                </InputLabel>

                <input
                  className='no-outline'
                  name='numemployees2021_dsh'
                  value={formik.values.numemployees2021_dsh}
                  onChange={onChangeNumberInput}
                  onBlur={formik.handleBlur}
                />
                <FormHelperText error>
                  {formik.errors.numemployees2021_dsh &&
                  formik.touched.numemployees2021_dsh
                    ? formik.errors.numemployees2021_dsh
                    : ''}
                </FormHelperText>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='taxform_dsh'>
                  Which tax form do you file?
                </InputLabel>
                <FormControl>
                  <Select
                    name='taxform_dsh'
                    value={formik.values.taxform_dsh}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label='Tax Form'
                  >
                    {taxFormOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error className='!m-0'>
                    {formik.errors.taxform_dsh && formik.touched.taxform_dsh
                      ? formik.errors.taxform_dsh
                      : ''}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className='text-field'>
                <InputLabel htmlFor='fiscalyearend_dsh'>
                  What month is your fiscal year end?
                </InputLabel>
                <FormControl>
                  <Select
                    name='fiscalyearend_dsh'
                    value={formik.values.fiscalyearend_dsh}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label='Fiscal Year End'
                  >
                    {months.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error className='!m-0'>
                    {formik.errors.fiscalyearend_dsh &&
                    formik.touched.fiscalyearend_dsh
                      ? formik.errors.fiscalyearend_dsh
                      : ''}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className='text-field'>
                <InputWithAutocomplete
                  options={years}
                  label='What is the first year in which you filed a tax return?'
                  name='firstyeartaxreturn_dsh'
                  error={
                    formik.errors.firstyeartaxreturn_dsh &&
                    formik.touched.firstyeartaxreturn_dsh
                      ? formik.errors.firstyeartaxreturn_dsh
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.firstyeartaxreturn_dsh.slice(0, 4)}
                  onChange={(e) => {
                    onChangeNumberField(
                      e.target.value.slice(0, 4),
                      e.target.name
                    );
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Box display={'flex'} justifyContent={'end'}>
          <LoadingButton
            loading={formik.isSubmitting}
            loadingPosition='start'
            startIcon={<CheckIcon />}
            variant='contained'
            type='submit'
            color='secondary'
          >
            Confirm
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default Survey;
