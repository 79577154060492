import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    text: {
      primary: '#283445',
      secondary: '#B22335',
    },
    primary: {
      main: '#B22335',
    },
    secondary: {
      main: '#2F3583',
    },
  },
});

export default theme;
