import {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
import { getAdressPrediction } from '../core/api';
import { InputWithAutocomplete } from './InputWithAutocomplete';

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  error: string;
  name: string;
  label: string;
}

export const AddressInput = ({
  onChange,
  value,
  onBlur,
  error,
  name,
  label,
}: Props) => {
  const isFocused = useRef(false);
  const [options, setOptions] = useState<string[]>([]);
  const debauncedValue = useDebouncedValue(value, 500);

  useEffect(() => {
    if (debauncedValue && isFocused.current) {
      getAdressPrediction(debauncedValue)
        .then((res) => {
          console.log('res', res);
          setOptions(
            res.predictions.map((v) => v.structured_formatting.main_text)
          );
        })
        .catch(console.error);
    }
  }, [debauncedValue, setOptions]);

  return (
    <InputWithAutocomplete
      label={label}
      name={name}
      options={options}
      error={error}
      onBlur={(e) => {
        onBlur(e);
        isFocused.current = false;
      }}
      value={value}
      onChange={onChange}
      onFocus={() => {
        isFocused.current = true;
      }}
    />
  );
};
