import { Box, Link, Typography } from '@mui/material';
import { FaFacebookF, FaYoutube, FaLinkedin } from 'react-icons/fa';
import { ReactComponent as PhoneIcon } from '../icons/phone.svg';

export const TopBar = () => {
  return (
    <Box bgcolor={'#B22335'} paddingY={2} paddingX={4}>
      <Box
        maxWidth={1250}
        margin={'0 auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={2}
        color={'white'}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Link
            href='tel:866-201-3262'
            display={'flex'}
            alignItems={'center'}
            gap={1}
            underline='hover'
          >
            <PhoneIcon />
            <Typography color={'white'}>866-201-3262</Typography>
          </Link>
          <Link
            href='mailto:info@smarterc.com'
            display={'flex'}
            alignItems={'center'}
            gap={1}
          >
            <Typography color={'white'}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='12'
                viewBox='0 0 16 12'
                fill='none'
              >
                <path
                  d='M14.5 0C15.3125 0 16 0.6875 16 1.5C16 2 15.75 2.4375 15.375 2.71875L8.59375 7.8125C8.21875 8.09375 7.75 8.09375 7.375 7.8125L0.59375 2.71875C0.21875 2.4375 0 2 0 1.5C0 0.6875 0.65625 0 1.5 0H14.5ZM6.78125 8.625C7.5 9.15625 8.46875 9.15625 9.1875 8.625L16 3.5V10C16 11.125 15.0938 12 14 12H2C0.875 12 0 11.125 0 10V3.5L6.78125 8.625Z'
                  fill='white'
                ></path>
              </svg>{' '}
            </Typography>
            <Typography color={'white'} className='elementor-icon-list-text'>
              info@smarterc.com
            </Typography>
          </Link>
          <Box color={'white'} display={'flex'} alignItems={'center'} gap={1}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='12'
              height='16'
              viewBox='0 0 12 16'
              fill='none'
            >
              <path
                d='M5.25 15.625C3.625 13.5938 0 8.75 0 6C0 2.6875 2.65625 0 6 0C9.3125 0 12 2.6875 12 6C12 8.75 8.34375 13.5938 6.71875 15.625C6.34375 16.0938 5.625 16.0938 5.25 15.625ZM6 8C7.09375 8 8 7.125 8 6C8 4.90625 7.09375 4 6 4C4.875 4 4 4.90625 4 6C4 7.125 4.875 8 6 8Z'
                fill='white'
              ></path>
            </svg>{' '}
            <Typography color={'white'}>
              350 N Lantana St. Suite #229 Camarillo, CA 93010
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Link href='https://www.facebook.com/mySmartERC' target='_blank'>
            <FaFacebookF color='white' />
          </Link>
          <Link href='https://www.youtube.com/@smarterc' target='_blank'>
            <FaYoutube color='white' />
          </Link>
          <Link
            href='https://www.linkedin.com/company/smarterc/'
            target='_blank'
          >
            <FaLinkedin color='white' />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
