import { Step as IStep } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { useSteps } from '../../contexts/useSteps';
import { Box } from '@mui/system';
import classNames from 'classnames';
import './MultiStepProgressBar.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { Alert } from '../Alert';
import { patchStep } from '../../core/api';
import { useToast } from '../../contexts/useToast';
import 'react-step-progress-bar/styles.css';
import { useContact } from '../../hooks/useContact';
interface Props {
  steps: IStep[];
}

const MultiStepProgressBar = ({ steps }: Props) => {
  const { contact } = useContact();
  const { logout } = useAuth0();
  const { currentStep, setCurrentStep } = useSteps();
  const { toast } = useToast();

  return (
    <div
      className='main-progress-bar mb-8 space-y-6 pt-6 w-full'
      data-testid='progress-bar'
    >
      <div className='dashboardSec'>
        <h1>Hello, {contact?.firstname_dsh}</h1>
        <Button variant='contained' color='primary' onClick={() => logout()}>
          Logout
        </Button>
      </div>

      <nav className='flex lg:gap-x-14 flex-col lg:flex-row gap-y-4'>
        <div className='flex flex-grow bg-white items-center rounded-md py-2 px-4 relative min-h-[77px]'>
          <ProgressBar
            percent={(currentStep * 100) / (steps.length - 1)}
            className='w-full'
          >
            {steps.map((step, index) => (
              <Step key={index}>
                {() => (
                  <Box>
                    <div
                      className={classNames('indexedStep', {
                        accomplished: currentStep > index,
                        current: currentStep === index,
                      })}
                    >
                      <div className='flex justify-center items-center bg-[rgb(223,220,220)] rounded-full overflow-hidden w-full h-full'>
                        <img
                          className={step.imageClassName || ''}
                          src={`images/${
                            step.imageComplete ? step.imageComplete : step.image
                          }`}
                          alt={`Step ${index + 1}`}
                        />
                      </div>
                    </div>
                  </Box>
                )}
              </Step>
            ))}
          </ProgressBar>
        </div>
        <div>
          <div className='rounded-md bg-white px-6 py-2 inline-block'>
            <div>Projected ERC</div>
            <div className='erc-info'>
              <img src='/images/flag.png' alt='Flag' />
              <div>189,000</div>
            </div>
          </div>
        </div>
      </nav>
      <Alert />
      {steps[currentStep]?.step !== 'Complete' && (
        <div className='flex items-center justify-between flex-wrap gap-6'>
          <div className='text-base flex gap-x-4 items-center shrink-0'>
            Next step:{' '}
            <div className='bg-white text-secondary rounded-md py-2 px-4 font-bold'>
              {steps[currentStep]?.step}{' '}
            </div>{' '}
          </div>
          <div>
            {currentStep > 1 && currentStep < steps.length - 1 && (
              <Button
                variant='contained'
                onClick={() => {
                  patchStep(currentStep + 1)
                    .then(() => {
                      setCurrentStep((prev) => prev + 1);
                    })
                    .catch((err) => {
                      toast('Something went wrong!', 'error');
                    });
                }}
              >
                Skip for now
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiStepProgressBar;
