import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Header';
import PageFive from '../components/PageFive/PageFive';
import PageSix from '../components/PageSix/PageSix';
import Survey2 from '../components/Survey2Page/Survey2';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '../components/Spinner';
import { Steps } from './Steps';
import { StepsProvider } from '../contexts/steps-context';
import { ToastProvider } from '../contexts/toast-context';
import { TopBar } from '../components/TopBar';
import { SocketProvider } from '../contexts/socket-context';
import { ContactProvider } from '../contexts/contact-context';

export function AuthedRoutes() {
  const {
    loginWithRedirect,
    isAuthenticated,
    user,
    isLoading,
    // logout
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
    //eslint-disable-next-line
  }, [isAuthenticated, isLoading]);

  if (isLoading || !user || !isAuthenticated) {
    return <Spinner />;
  }

  return (
    <ContactProvider>
      <SocketProvider>
        <ToastProvider>
          <div className='min-h-screen flex flex-col'>
            <TopBar />
            <Header />
            <div className='px-8 pb-6 flex-grow flex flex-col w-full'>
              <div className='max-w-[1250px] m-auto w-full flex-grow flex flex-col'>
                <Routes>
                  <Route
                    path='/'
                    element={
                      <StepsProvider>
                        <Steps />
                      </StepsProvider>
                    }
                  />
                  <Route path='/business-survey' element={<Survey2 />} />
                  <Route path='/pagefive' element={<PageFive />} />
                  <Route path='/pagesix' element={<PageSix />} />
                </Routes>
              </div>
            </div>
          </div>
        </ToastProvider>
      </SocketProvider>
    </ContactProvider>
  );
}
