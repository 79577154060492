import {
  Autocomplete,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material';
import { ChangeEventHandler, FocusEventHandler } from 'react';

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  error: string;
  options: string[];
  name: string;
  label: string;
  freeSolo?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement>;
}

export const InputWithAutocomplete = ({
  onChange,
  value,
  onBlur,
  error,
  options,
  name,
  label,
  freeSolo = true,
  onFocus,
}: Props) => {
  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Autocomplete
        onSelect={onChange}
        className='[&>div>div]:!py-0 [&>div>div]:!pl-0'
        value={value}
        options={options.filter((o, i, self) => self.indexOf(o) === i)}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        freeSolo={freeSolo}
        disableClearable
        renderInput={(params: any) => (
          <TextField
            {...params}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            onFocus={onFocus}
          />
        )}
      />
      <FormHelperText error>{error}</FormHelperText>
    </>
  );
};
