import { IoIosWarning } from 'react-icons/io';
import { ReactComponent as PhoneIcon } from '../icons/phone.svg';

interface AlertProps {
  content?: string | JSX.Element;
}

export const Alert = ({
  content = (
    <div className='text-white text-semibold flex items-center gap-x-4 text-lg flex-wrap pr-4'>
      If you see a mistake or need to change any information, call our Customer
      Service Line
      <a
        href='tel:866-201-3262'
        className='flex gap-2 items-center text-white font-semibold underline hover:no-underline'
      >
        <PhoneIcon className='shrink-0 mt-[2px]' />
        866-201-3262
      </a>
    </div>
  ),
}: AlertProps) => {
  return (
    <div className='flex flex-row pl-4 py-2 gap-x-2 items-start border rounded-md shadow overflow-hidden bg-primary'>
      <IoIosWarning size={24} className='text-white mt-[2px] shrink-0' />
      <div className='text-sm dark:text-gray-100'>{content}</div>
    </div>
  );
};
