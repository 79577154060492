import { PropsWithChildren, createContext, useState } from 'react';
import { InviteStatusEnum } from '../types';

interface StepsContextType {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setFileInviteStatus: React.Dispatch<
    React.SetStateAction<InviteStatusEnum | undefined>
  >;
  fileInviteStatus: InviteStatusEnum | undefined;
}

export const StepsContext = createContext<StepsContextType>(
  {} as StepsContextType
);

export const StepsProvider = ({ children }: PropsWithChildren) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [fileInviteStatus, setFileInviteStatus] = useState<InviteStatusEnum>();

  return (
    <StepsContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        fileInviteStatus,
        setFileInviteStatus,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};
