import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { Contact } from '../types';
import { getContact } from '../core/api';

interface ContactContextType {
  contact?: Contact;
  isLoadingContact: boolean;
  setContact: React.Dispatch<React.SetStateAction<Contact | undefined>>;
}

export const ContactContext = createContext<ContactContextType>(
  {} as ContactContextType
);

export const ContactProvider = ({ children }: PropsWithChildren) => {
  const [contact, setContact] = useState<Contact>();
  const [isLoadingContact, setIsLoadingContact] = useState(false);

  useEffect(() => {
    setIsLoadingContact(true);
    getContact()
      .then((res) => {
        setContact(res);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingContact(false);
      });
  }, []);

  return (
    <ContactContext.Provider value={{ contact, isLoadingContact, setContact }}>
      {children}
    </ContactContext.Provider>
  );
};
