import { DependencyList, useEffect } from 'react';
import { InviteStatusChangedEvent, UserEnvelopeEvent } from '../types';

export const WS_MESSAGE_EVENT_KEY = 'ws-message';

export type WsMessage = {
  type: string;
  detail: any;
};

export const isEnvelopeEvent = (v: any): v is UserEnvelopeEvent => {
  return v.event === 'envelope-completed';
};

export const isTaxstatusEvent = (v: any): v is UserEnvelopeEvent => {
  return v.event === 'taxstatus_consent_given';
};

export const isFileinviteEvent = (v: any): v is InviteStatusChangedEvent => {
  return v.event === 'invite_status_changed';
};

export const useWSMessageHandler = (
  handler: (msg: WsMessage) => void,
  deps: DependencyList
) => {
  useEffect(() => {
    const listener = (e: CustomEvent) => {
      handler(e.detail);
    };
    document.addEventListener(WS_MESSAGE_EVENT_KEY as any, listener);
    return () => {
      document.removeEventListener(WS_MESSAGE_EVENT_KEY as any, listener);
    };
    // eslint-disable-next-line
  }, deps);
};
